define("@fleetors/ordershub-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('home', {
      path: '/'
    });
    this.route('products', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('index', {
          path: '/'
        }, function () {
          this.route('edit', {
            path: '/:public_id'
          });
        });
        this.route('category', {
          path: '/:slug'
        }, function () {
          this.route('new');
          this.route('edit', {
            path: '/:public_id'
          });
        });
      });
    });
    this.route('customers', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('edit', {
          path: '/:public_id'
        });
        this.route('view', {
          path: '/:public_id'
        });
      });
    });
    this.route('orders', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('new');
        this.route('edit', {
          path: '/:public_id'
        });
        this.route('view', {
          path: '/:public_id'
        });
      });
    });
    this.route('networks', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('network', {
          path: '/:public_id'
        }, function () {
          this.route('stores');
          this.route('customers');
          this.route('orders');
        });
      });
    });
    this.route('promotions');
    this.route('coupons');
    this.route('broadcast');
    this.route('pages');
    this.route('settings', function () {
      this.route('index', {
        path: '/'
      });
      this.route('api');
      this.route('locations');
      this.route('gateways');
      this.route('notifications');
    });
  });
});