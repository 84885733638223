define("@fleetors/fleetcore-data/serializers/purchase-rate", ["exports", "@fleetors/ember-core/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PurchaseRateSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        service_quote: {
          embedded: 'always'
        }
      };
    }
  }
  _exports.default = PurchaseRateSerializer;
});