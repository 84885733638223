define("@fleetors/fleetcore-data/serializers/service-rate", ["exports", "@fleetors/ember-core/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ServiceRateSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        zone: {
          embedded: 'always'
        },
        service_area: {
          embedded: 'always'
        },
        parcel_fees: {
          embedded: 'always'
        },
        rate_fees: {
          embedded: 'always'
        }
      };
    }
  }
  _exports.default = ServiceRateSerializer;
});