define("@fleetors/scm-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('home', {
      path: '/'
    });
    this.route('products', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('new');
        this.route('details', {
          path: '/:public_id'
        });
        this.route('edit', {
          path: '/edit/:public_id'
        });
      });
    });
    this.route('inventory', function () {
      this.route('low-stock');
      this.route('expired-stock');
      this.route('index', {
        path: '/'
      }, function () {
        this.route('new');
        this.route('new-stock-adjustment');
        this.route('details', {
          path: '/:public_id'
        });
        this.route('edit', {
          path: '/edit/:public_id'
        });
      });
    });
    this.route('warehouses', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('new');
        this.route('details', {
          path: '/:public_id'
        });
        this.route('edit', {
          path: '/edit/:public_id'
        });
      });
    });
    this.route('suppliers', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('new');
        this.route('details', {
          path: '/:public_id'
        });
        this.route('edit', {
          path: '/edit/:public_id'
        });
      });
    });
    this.route('sales-orders', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('new');
        this.route('details', {
          path: '/:public_id'
        });
        this.route('edit', {
          path: '/edit/:public_id'
        });
      });
    });
    this.route('purchase-orders', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('new');
        this.route('details', {
          path: '/:public_id'
        });
        this.route('edit', {
          path: '/edit/:public_id'
        });
      });
    });
    this.route('batch', function () {});
    this.route('audits', function () {});
    this.route('reports', function () {});
  });
});