define("@fleetors/fleetcore-data/transforms/polygon", ["exports", "@ember-data/serializer/transform", "@fleetors/fleetcore-data/utils/geojson/polygon", "@ember/utils"], function (_exports, _transform, _polygon, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PolygonTransform extends _transform.default {
    deserialize(serialized) {
      if ((0, _utils.isNone)(serialized)) {
        return serialized;
      }
      return new _polygon.default(serialized);
    }
    serialize(deserialized) {
      if ((0, _utils.isNone)(deserialized)) {
        return deserialized;
      }
      return new _polygon.default(deserialized);
    }
  }
  _exports.default = PolygonTransform;
});