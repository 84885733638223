define("@fleetors/ember-accounting/helpers/format-number", ["exports", "@fleetors/ember-accounting/utils/format-number", "@ember/component/helper"], function (_exports, _formatNumber, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function formatNumber([value], options) {
    return (0, _formatNumber.default)(value, options);
  });
});