define("@fleetors/fleetcore-data/serializers/fuel-report", ["exports", "@fleetors/ember-core/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FuelReportSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        driver: {
          embedded: 'always'
        },
        vehicle: {
          embedded: 'always'
        },
        reporter: {
          embedded: 'always'
        }
      };
    }

    /**
     * Customize serializer so that any attributes that are instances of Models or objects
     * that are to accept and ID get serialized into the id only
     *
     * @param {Snapshot} snapshot
     * @param {Object} options
     * @return {Object} json
     */
    serialize() {
      const json = super.serialize(...arguments);

      // remove automatically set `reporter_uuid`
      delete json.reporter_uuid;
      return json;
    }
  }
  _exports.default = FuelReportSerializer;
});