define("@fleetors/fleetcore-data/serializers/payload", ["exports", "@fleetors/ember-core/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PayloadSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        pickup: {
          embedded: 'always'
        },
        dropoff: {
          embedded: 'always'
        },
        return: {
          embedded: 'always'
        },
        waypoints: {
          embedded: 'always'
        },
        entities: {
          embedded: 'always'
        }
      };
    }

    /**
     * Modify the serialization process to include the fleet UUIDs.
     *
     * @param {Snapshot} snapshot
     * @param {Object} json
     * @param {Object} options
     */
    serialize(snapshot, options) {
      let json = super.serialize(...arguments);

      // Include the fleet UUIDs in the payload
      json.pickup_fleet_uuid = snapshot.attr('pickup_fleet_uuid');
      json.dropoff_fleet_uuid = snapshot.attr('dropoff_fleet_uuid');
      return json;
    }

    /**
     * Customize the deserialization process to handle the fleet UUIDs.
     *
     * @param {Object} payload
     * @param {Store} store
     * @param {ModelClass} primaryModelClass
     * @param {String} id
     * @param {String} requestType
     */
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // Ensure the fleet UUIDs are properly deserialized
      if (payload.pickup_fleet_uuid) {
        payload.pickup_fleet_uuid = payload.pickup_fleet_uuid;
      }
      if (payload.dropoff_fleet_uuid) {
        payload.dropoff_fleet_uuid = payload.dropoff_fleet_uuid;
      }
      return super.normalizeResponse(...arguments);
    }
  }
  _exports.default = PayloadSerializer;
});