define("@fleetors/fleetcore-data/transforms/point", ["exports", "@ember-data/serializer/transform", "@fleetors/fleetcore-data/utils/geojson/point", "@ember/utils"], function (_exports, _transform, _point, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PointTransform extends _transform.default {
    deserialize(serialized) {
      if ((0, _utils.isNone)(serialized)) {
        return new _point.default(0, 0);
      }
      return new _point.default(serialized);
    }
    serialize(deserialized) {
      if ((0, _utils.isNone)(deserialized)) {
        return new _point.default(0, 0);
      }
      return new _point.default(deserialized);
    }
  }
  _exports.default = PointTransform;
});