define("@fleetors/fleetcore-data/serializers/equipment-timesheet", ["exports", "@fleetors/ember-core/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EquipmentTimesheetSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        vehicle: {
          embedded: 'always'
        },
        serviceArea: {
          embedded: 'always'
        },
        fleet: {
          embedded: 'always'
        } // Add fleet to embedded attributes
      };
    }
    serialize(snapshot, options) {
      let json = super.serialize(snapshot, options);

      // Remove id field if it is null or undefined
      if (!json.id) {
        delete json.id;
      }
      return json;
    }
    serializeBelongsTo(snapshot, json, relationship) {
      let key = relationship.key;

      // Skip serialization for vehicle and serviceArea
      if (key === 'vehicle' || key === 'serviceArea') {
        return;
      } else if (key === 'fleet') {
        // Ensure the fleet relationship is properly serialized with type and id
        let belongsTo = snapshot.belongsTo(key);
        if (belongsTo) {
          json[relationship.key] = {
            id: belongsTo.id,
            type: 'fleet' // Ensure the type is set to 'fleet'
          };
        }
      } else {
        super.serializeBelongsTo(...arguments);
      }
    }
  }
  _exports.default = EquipmentTimesheetSerializer;
});