define("@fleetors/iam-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('home', {
      path: '/'
    }, function () {});
    this.route('users', function () {});
    this.route('groups', function () {});
    this.route('roles', function () {});
    this.route('policies', function () {});
  });
});