define("@fleetors/fleetcore-data/serializers/fleet", ["exports", "@fleetors/ember-core/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FleetSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        vendor: {
          embedded: 'always'
        },
        parent_fleet: {
          embedded: 'always'
        },
        subfleets: {
          embedded: 'always'
        },
        service_area: {
          embedded: 'always'
        },
        zone: {
          embedded: 'always'
        },
        drivers: {
          embedded: 'always'
        },
        vehicles: {
          embedded: 'always'
        },
        manager: {
          embedded: 'always'
        }
      };
    }
    serializeBelongsTo(snapshot, json, relationship) {
      let key = relationship.key;
      if (key === 'parent_fleet') {
        return;
      }
      return super.serializeBelongsTo(...arguments);
    }
  }
  _exports.default = FleetSerializer;
});