define("@fleetors/fleetcore-data/utils/geojson/closed-polygon", ["exports", "@fleetors/fleetcore-data/utils/geojson/points-equal"], function (_exports, _pointsEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = closedPolygon;
  function closedPolygon(coordinates) {
    var outer = [];
    for (var i = 0; i < coordinates.length; i++) {
      var inner = coordinates[i].slice();
      if ((0, _pointsEqual.default)(inner[0], inner[inner.length - 1]) === false) {
        inner.push(inner[0]);
      }
      outer.push(inner);
    }
    return outer;
  }
});